import React, { useEffect, useState } from "react"
import Page from "../components/spark-express/page"
import "../assets/scss/typography.scss"
import "../assets/css/layout.css"
import Login from "../components/spark-express/login/container"
import useAuth from "../custom-hooks/auth"

const Application = () => {
  useAuth(); 
  return (
    <Page>
      <Login />
    </Page>
  )
}

export default Application
