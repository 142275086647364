import React, { useState } from "react"
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff"
import VisibilityIcon from "@material-ui/icons/Visibility"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Typography } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import { IconButton } from "@material-ui/core"


import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

import { Formik, Form, useField } from "formik"

import CustomTextField from "./CustomTextField"

const styles = makeStyles((theme) => ({
  textBox: {
    fontFamily: "visby",
    marginBottom: "0.2em",
    fontSize: "0.6em",
  },
  form: {
    marginTop: "14em  ",
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    backgroundColor: "#FFCF10",
  },
}))

const LoginPage = ({ loading, onSubmit }) => {
  const classes = styles()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Container  className={classes.form}>
      <Formik initialValues={{ email: "", password: "" }} onSubmit={onSubmit}>
        {() => (
          <>
            <Typography variant="h3">Spark Express</Typography>
            <Form>
              <Grid container spacing={1} alignItems="flex-end" margin>
                <Grid item xs={12} className={classes.textBox}>
                  <CustomTextField label="Email" name="email" />
                </Grid>
              </Grid>
              <Grid container spacing={1} alignItems="flex-end" margin>
                <Grid item xs={12} className={classes.textBox}>
                  <CustomTextField
                    label="Password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setShowPassword(!showPassword)
                          }}
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                disabled={loading}
                type="submit"
                fullWidth
                className={classes.submit}
              >
                {loading ? <CircularProgress color="secondary" /> : "Login"}
              </Button>
            </Form>
          </>
        )}
      </Formik>
      <Link to="/register">
        <Typography variant="subtitle1">New to here? Register here</Typography>
      </Link>
    </Container>
  )
}

export default LoginPage
